import React from 'react'
import { ReactComponent as IconVk } from '../../assets/images/icons/vk.svg'
import { ReactComponent as IconInstagram } from '../../assets/images/icons/instagram.svg'
import { ReactComponent as IconTikTok } from '../../assets/images/icons/tiktok.svg'
import { ReactComponent as IconYandex } from '../../assets/images/icons/yandex.svg'
import { ReactComponent as IconSpotify } from '../../assets/images/icons/spotify.svg'
import { ReactComponent as IconYoutube } from '../../assets/images/icons/youtube.svg'
import { ReactComponent as Music } from '../../assets/images/icons/music.svg'

const Footer = React.memo(({ audioMuted, toggleMuted }) => {
  return (
    <div className="footer">
      <div className="footer-social">
        <a
          href="https://vk.com/notanastronaut"
          className="footer-social-link footer-social-link_vk"
          target="_blank"
          rel="noreferrer"
          title="VK"
        >
          <IconVk />
        </a>
        <a
          href="https://www.instagram.com/notanastronaut_band/"
          className="footer-social-link footer-social-link_instagram"
          target="_blank"
          rel="noreferrer"
          title="Instagram"
        >
          <IconInstagram />
        </a>
        <a
          href="https://www.tiktok.com/@notanastronaut_band"
          className="footer-social-link footer-social-link_tiktok"
          target="_blank"
          rel="noreferrer"
          title="TikTok"
        >
          <IconTikTok />
        </a>
        <a
          href="https://music.yandex.ru/artist/9047804"
          className="footer-social-link footer-social-link_yandex"
          target="_blank"
          rel="noreferrer"
          title="Yandex Music"
        >
          <IconYandex />
        </a>
        <a
          href="https://open.spotify.com/artist/50GWhSKHRUctp2bPh1MGnI?si=rmIoifHTTYmiqed5Z7zruw"
          className="footer-social-link footer-social-link_spotify"
          target="_blank"
          rel="noreferrer"
          title="Spotify"
        >
          <IconSpotify />
        </a>
        <a
          href="https://www.youtube.com/channel/UCrKo1kqXZVPqNK5pqWt-ewA"
          className="footer-social-link footer-social-link_youtube"
          target="_blank"
          rel="noreferrer"
          title="YouTube"
        >
          <IconYoutube />
        </a>
      </div>

      <div className="footer-bottom">
        <div className="footer-copyright">© not an astronaut | 2020 — {new Date().getFullYear()}</div>
        {toggleMuted && (
          <button
            className={`footer-music${!audioMuted ? ' footer-music_active' : ''}`}
            onClick={(ev) => {
              ev.stopPropagation()
              toggleMuted()
            }}
          >
            <Music />
          </button>
        )}
      </div>
    </div>
  )
})

export default Footer
