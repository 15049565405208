import React from 'react'

const VideoPage = React.memo(() => {
  return (
    <div className="video-container">
      <iframe
        className="video-iframe"
        src="https://www.youtube.com/embed/OYrFA2rnZxI?si=ca8tNMzc0XpahiBq"
        title="not an astronaut - миллиарды людей"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        rel="0"
      />
    </div>
  )
})

export default VideoPage
