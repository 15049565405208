import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { YMInitializer } from 'react-yandex-metrika'
import DefaultLayout from './components/layout/defaultLayout'
import MainPage from './components/pages/mainPage'
import NewsPage from './components/pages/newsPage'
import MusicPage from './components/pages/musicPage'
import VideoPage from './components/pages/videoPage'

const routesConfig = [
  { exact: true, path: '/', className: 'main', page: 'main', component: <MainPage /> },
  { exact: true, path: '/news/', className: 'news', page: 'news', component: <NewsPage /> },
  { exact: true, path: '/music/', className: 'music', page: 'music', component: <MusicPage /> },
  { exact: true, path: '/video/', className: 'video', page: 'video', component: <VideoPage /> },
]

const App = React.memo(() => {
  return (
    <div className="App">
      <YMInitializer
        accounts={[95752243]}
        version="2"
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true,
        }}
      />
      <Router>
        <Routes>
          {routesConfig.map((route, index) => {
            const { exact, path, className, page, component } = route
            return (
              <Route
                key={index}
                exact={exact}
                path={path}
                element={
                  <DefaultLayout className={className} page={page}>
                    {component}
                  </DefaultLayout>
                }
              />
            )
          })}
        </Routes>
      </Router>
    </div>
  )
})

export default App
