import React, { useEffect, useRef, useState } from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import Stars from '../common/stars'
import audio from '../../assets/zarya1.mp3'

const DefaultLayout = React.memo(({ children, className, page }) => {
  const bgRef = useRef()
  const audioRef = useRef()

  const audioAllowed = ['main', 'news'].includes(page)
  const [audioMuted, setAudioMuted] = useState(localStorage.getItem('astronautMuted') || false)

  const toggleMuted = () => {
    localStorage.setItem('astronautMuted', `${!audioMuted}`)
    setAudioMuted(!audioMuted)
  }

  const postMessageListener = (ev) => {
    const { event } = ev.data
    if (event === 'astronautRedirect') {
      bgRef.current?.classList.add('bg_redirect')
      setTimeout(() => bgRef.current?.classList.remove('bg_redirect'), 2000)
    }
    if (event === 'astronautMute') {
      setAudioMuted(!audioMuted)
    }
  }

  useEffect(() => {
    audioRef.current.volume = 0.05
    window.addEventListener('message', postMessageListener)
    return () => {
      window.removeEventListener('message', postMessageListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (audioAllowed && !audioMuted) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }, [audioAllowed, audioMuted])

  useEffect(() => {
    if (audioMuted) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
  }, [audioMuted])

  return (
    <>
      <Header />
      <div ref={bgRef} className={`bg${page ? ` bg_${page}` : ''}`}>
        <div className="bg-image" />
      </div>
      <audio ref={audioRef} src={audio} autoPlay loop muted={audioMuted} />
      <Stars />
      <main className={className}>{children}</main>
      <Footer audioMuted={audioMuted} toggleMuted={audioAllowed ? toggleMuted : undefined} />
    </>
  )
})

export default DefaultLayout
