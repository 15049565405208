import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const Header = React.memo(() => {
  const goTo = useNavigate()
  const { pathname } = useLocation()

  const [redirectingTo, setRedirectingTo] = useState('')
  const [mobileOpened, setMobileOpened] = useState(false)

  const linksConfig1 = [
    { path: '/', title: 'Home' },
    { path: '/news/', title: 'News' },
  ]
  const linksConfig2 = [
    { path: '/music/', title: 'Music' },
    { path: '/video/', title: 'Video' },
  ]

  const onLinkClick = (ev, path) => {
    ev.preventDefault()
    if (pathname === path) return
    setMobileOpened(false)
    setRedirectingTo(path)
    window.postMessage({ event: 'astronautRedirect' }, '*')
    setTimeout(() => {
      setRedirectingTo('')
      goTo(path)
    }, 1000)
  }

  return (
    <header className="header">
      <div className="header-nav">
        {linksConfig1.map(({ path, title }, index) => (
          <a
            key={title + index}
            className={`header-nav-item${pathname === path && !redirectingTo ? ' header-nav-item_active' : ''}${
              redirectingTo === path ? ' header-nav-item_redirecting' : ''
            }`}
            href={path}
            onClick={(ev) => onLinkClick(ev, path)}
          >
            <span>{title}</span>
          </a>
        ))}
      </div>

      <div className="header-logo" onClick={(ev) => onLinkClick(ev, '/')} />

      <div className="header-nav">
        {linksConfig2.map(({ path, title }, index) => (
          <a
            key={title + index}
            className={`header-nav-item${pathname === path && !redirectingTo ? ' header-nav-item_active' : ''}${
              redirectingTo === path ? ' header-nav-item_redirecting' : ''
            }`}
            href={path}
            onClick={(ev) => onLinkClick(ev, path)}
          >
            <span>{title}</span>
          </a>
        ))}
      </div>

      <div className={`header-nav-mobile header-nav-mobile_opened-${mobileOpened}`}>
        {mobileOpened &&
          [...linksConfig1, ...linksConfig2].map(({ path, title }, index) => (
            <a
              key={index + title}
              className={`header-nav-item${pathname === path && !redirectingTo ? ' header-nav-item_active' : ''}${
                redirectingTo === path ? ' header-nav-item_redirecting' : ''
              }`}
              href={path}
              onClick={(ev) => onLinkClick(ev, path)}
            >
              <span>{title}</span>
            </a>
          ))}
      </div>

      <button
        className={`header-nav-menu-btn header-nav-menu-btn_opened-${mobileOpened}`}
        onClick={() => setMobileOpened(!mobileOpened)}
      >
        <span />
        <span />
        <span />
      </button>
    </header>
  )
})

export default Header
