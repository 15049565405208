import React from 'react'

const MusicPage = React.memo(() => {
  return (
    <div className="music-widget">
      {/*<iframe title="ya.music" frameBorder="0" src="https://music.yandex.ru/iframe/#artist/9047804" />*/}
      <iframe title="эхо" frameBorder="0" src="https://music.yandex.ru/iframe/album/31725192">
        Слушайте <a href="https://music.yandex.ru/album/31725192">эхо</a> —{' '}
        <a href="https://music.yandex.ru/artist/9047804">not an astronaut</a> на Яндекс Музыке
      </iframe>
      <iframe title="mayday" frameBorder="0" src="https://music.yandex.ru/iframe/album/28210715">
        Слушайте <a href="https://music.yandex.ru/album/28210715">mayday</a> —{' '}
        <a href="https://music.yandex.ru/artist/9047804">not an astronaut</a> на Яндекс Музыке
      </iframe>
    </div>
  )
})

export default MusicPage
