import React /*, { useEffect, useRef }*/ from 'react'

const NewsPage = React.memo(() => {
  /*const blockRef = useRef(null)

  const initVK = () => {
    try {
      blockRef?.current.querySelector('#vk_groups')?.remove()

      const newBlock = document.createElement('div')
      newBlock.id = 'vk_groups'
      blockRef?.current?.append(newBlock)

      const width = newBlock?.clientWidth
      const height = newBlock?.clientHeight
      // eslint-disable-next-line
      VK.Widgets.Group('vk_groups', { mode: 4, wide: 1, width: width, height: height, no_cover: 1 }, 24271539)
    } catch (error) {
      console.log(error)
    }
  }*/

  /*useEffect(() => {
    initVK()
    window.addEventListener('resize', initVK, false)

    return () => {
      window.removeEventListener('resize', initVK, false)
    }
  }, [])*/

  /*return <div ref={blockRef} className="news-vk" />*/
  return (
    <div className="news-vk">
      <div className="elfsight-app-964b9289-c091-4d08-aab4-c6aca00a5844" data-elfsight-app-lazy></div>
    </div>
  )
})

export default NewsPage
