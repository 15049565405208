import React, { useEffect, useRef } from 'react'

const Stars = React.memo(() => {
  const canvasRef = useRef()
  const stars = useRef([])
  const resizeTimeout = useRef()
  const resizeCooldown = useRef(500)
  const lastResizeTime = useRef(Date.now())
  const ms = useRef(16)
  const lastPaintTime = useRef(0)

  const initializeBackground = () => {
    canvasRef.current.width = window.innerWidth
    canvasRef.current.height = window.innerHeight
    window.addEventListener('resize', function () {
      if (Date.now() - lastResizeTime.current < resizeCooldown.current && resizeTimeout.current) {
        clearTimeout(resizeTimeout.current)
        resizeTimeout.current = null
      }

      lastResizeTime.current = Date.now()
      canvasRef.current.style.display = 'none'
      resizeTimeout.current = setTimeout(() => {
        fadeIn(canvasRef.current, 500)
        initializeStars()
      }, 500)
      canvasRef.current.width = window.innerWidth
      canvasRef.current.height = window.innerHeight
    })
    initializeStars()
    ;(window.requestAnimationFrame && requestAnimationFrame(paintLoop)) || setTimeout(paintLoop, ms.current)
  }

  const rand = (max) => {
    return Math.random() * max
  }

  function Star(canvas, size, speed) {
    this.ctx = canvas.getContext('2d')
    this.size = size
    this.speed = speed
    this.x = rand(window.innerWidth)
    this.y = rand(window.innerHeight)
  }

  Star.prototype.animate = function (delta) {
    this.x += this.speed * delta
    this.y -= this.speed * delta
    if (this.y < 0) {
      this.y = window.innerHeight
    }
    if (this.x > window.innerWidth) {
      this.x = 0
    }
    this.ctx.fillStyle = '#ffffff'
    this.ctx.fillRect(this.x, this.y, this.size, this.size)
  }

  const initializeStars = (speed) => {
    const winArea = window.innerWidth * window.innerHeight
    const smallStarsDensity = 0.0001
    const mediumStarsDensity = 0.00005
    const largeStarsDensity = 0.00002
    const smallStarsCount = winArea * smallStarsDensity
    const mediumStarsCount = winArea * mediumStarsDensity
    const largeStarsCount = winArea * largeStarsDensity
    stars.current = []
    for (let i = 0; i < smallStarsCount; i++) {
      stars.current.push(new Star(canvasRef.current, 1, speed ? speed + 200 : 30))
    }

    for (let i = 0; i < mediumStarsCount; i++) {
      stars.current.push(new Star(canvasRef.current, 2, speed ? speed + 100 : 20))
    }

    for (let i = 0; i < largeStarsCount; i++) {
      stars.current.push(new Star(canvasRef.current, 3, speed || 10))
    }
  }

  const drawStars = (delta) => {
    for (let i = 0; i < stars.current.length; i++) {
      stars.current[i].animate(delta)
    }
  }

  const paintLoop = (timestamp) => {
    canvasRef.current?.getContext('2d').clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    let delta = (window.requestAnimationFrame ? timestamp - lastPaintTime.current : ms.current) / 1000
    if (delta > 0.05) {
      delta = 0.05
    }
    drawStars(delta)
    ;(window.requestAnimationFrame && requestAnimationFrame(paintLoop)) || setTimeout(paintLoop, ms.current)
    lastPaintTime.current = timestamp
  }

  const fadeIn = (element, duration, callback) => {
    element.style.opacity = 0
    element.style.display = 'block'

    const startTime = Date.now()
    const tick = function () {
      let newOpacity = (Date.now() - startTime) / duration
      if (newOpacity > 1) {
        newOpacity = 1
        callback && callback()
      } else {
        ;(window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16)
      }

      element.style.opacity = newOpacity
    }
    tick()
  }

  const postMessageListener = (ev) => {
    const { event } = ev.data
    if (event === 'astronautRedirect') {
      initializeStars(200)
      setTimeout(initializeStars, 2000)
    }
  }

  useEffect(() => {
    initializeBackground()
    window.addEventListener('message', postMessageListener)
    return () => {
      window.removeEventListener('message', postMessageListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <canvas ref={canvasRef} id="stars" style={{ position: 'relative' }} />
})

Stars.displayName = 'Stars'

export default Stars
